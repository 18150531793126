import { companyManagerRole, companyProjectRole } from './constants'
import {
  equal,
  firstCapAndSplit,
  length,
  notEqual,
  ternary,
} from './javascript'
import { loadStateFn, saveStateFn } from './localStorage'

const sideBarValues = [
  'Approval Matrix',
  'General Ledger Upload',
  'Cost Report Upload',
  'Submissions',
  'Audit',
  'Report Builder',
  'Team Members',
  'Projects',
  'Add Chart Account',
  'Submission Detail',
]

const setDynamicColumns = (columnList) => {
  const fields = ['__typename', 'id', 'formName', 'submissionStage']
  const column = []
  if (columnList) {
    columnList.forEach((item) => {
      if (!fields.includes(item)) {
        if (equal(item, 'srNo')) {
          column.push({
            Header: 'SR No.',
            accessor: item,
            filter: 'fuzzyText',
          })
        } else {
          column.push({
            Header: firstCapAndSplit(item),
            accessor: item,
            filter: 'fuzzyText',
          })
        }
      }
    })
  }

  return column
}
export default setDynamicColumns

export const setDynamicColumnsForQB = (columnList) => {
  const column = []
  if (columnList) {
    columnList.forEach((item) => {
      column.push({
        Header: firstCapAndSplit(item),
        accessor: item,
        filter: 'fuzzyText',
      })
    })
  }

  return column
}

export const checkDateNaN = (dateVale, type = false) => {
  if (equal(dateVale, 'NaN-NaN-NaN') || equal(dateVale, 'NaN/NaN/NaN'))
    return ''
  if (equal(dateVale, 'CHECK_REQUEST')) return 'Check Request'
  if (equal(dateVale, 'PURCHASE_ORDER')) return 'Purchase Order'
  if (equal(dateVale, 'WIRE_TRANSFER')) return 'Wire Transfer'
  if (equal(dateVale, 'CREDIT_CARD_CHARGE')) return 'Credit Card Charge'
  if (equal(dateVale, 'AP_INVOICE')) return 'AP Invoice'

  if (type) {
    const val = dateVale?.match(/[a-z]+|[^a-z]+/gi)
    let newVal = ''
    val.forEach((e) => {
      newVal += ` ${e}`
    })
    return newVal
  }

  return dateVale
}

export const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const setDateFormat = (date, month, year) => {
  let [d, m] = [date, month + 1]
  const y = year
  if (d < 10) d = `0${d}`
  if (m < 10) m = `0${m}`
  return { d, m, y }
}
export const dateMonthYear = (date, month, year) => {
  const { d, m, y } = setDateFormat(date, month, year)
  return `${m}/${d}/${y}`
}

export const convertYMDtoMDY = (date) => {
  const newDate = new Date(date)
  return dateMonthYear(
    newDate.getDate(),
    newDate.getMonth(),
    newDate.getFullYear(),
  )
}
export const convertDatetoMDY = (date) => {
  const [fullDate] = date?.split('T')
  const [year, month, day] = fullDate?.split('-')
  return year && month && day ? `${month}/${day}/${year}` : ''
}

export const convertUTCMDY = (date) => {
  const newDate = new Date(date)
  return dateMonthYear(
    newDate.getUTCDate(),
    newDate.getUTCMonth(),
    newDate.getUTCFullYear(),
  )
}

export const yearDateMonth = (date, month, year) => {
  const { d, m, y } = setDateFormat(date, month, year)
  return `${y}-${m}-${d}`
}

export const convertMDYtoYMD = (date) => {
  const newDate = new Date(date)
  return yearDateMonth(
    newDate.getDate(),
    newDate.getMonth(),
    newDate.getFullYear(),
  )
}

export const splitUnderScoreJoinAndLowerCase = (string) => {
  if (string !== null) {
    return string.split('_').join(' ').toLowerCase()
  }
  return string
}

export const splitNumberString = (value) => value.match(/[a-z]+|[^a-z]+/gi)

export const currentDateMMDDYYYY = () => {
  const date = new Date()
  return `${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}`
}

export const setSubmissionType = (type) => {
  if (equal(type, 'CHECK_REQUEST')) return 'Check Request'
  if (equal(type, 'PURCHASE_ORDER')) return 'Purchase Order'
  if (equal(type, 'WIRE_TRANSFER')) return 'Wire Transfer'
  if (equal(type, 'AP_INVOICE')) return 'AP Invoice'
  return 'Credit Card Charge'
}

export const checkDateNotNaN = (dateVale) => {
  if (equal(dateVale, 'NaN-NaN-NaN')) return ''
  return dateVale
}

export const setReportBuilderColumn = (columnList) => {
  const fields = ['__typename', 'id']
  const column = []
  if (columnList) {
    columnList.forEach((item) => {
      if (!fields.includes(item)) {
        if (equal(item, 'srNo')) {
          column.push({
            Header: 'SR No.',
            accessor: item,
            filter: 'fuzzyText',
          })
        } else {
          column.push({
            Header: firstCapAndSplit(item),
            accessor: item,
            filter: 'fuzzyText',
          })
        }
      }
    })
  }

  return column
}

export const today = () => {
  let dd = new Date().getDate()
  let mm = new Date().getMonth() + 1
  const yy = new Date().getFullYear()
  if (dd < 10) dd = `0${dd}`
  if (mm < 10) mm = `0${mm}`
  return `${mm}-${dd}-${yy}`
}

export const CapitalizeSubmissions = (type) => {
  if (equal(type, 'CHECK_REQUEST')) return 'Check Request'
  if (equal(type, 'PURCHASE_ORDER')) return 'Purchase Order'
  if (equal(type, 'WIRE_TRANSFER')) return 'Wire Transfer'
  if (equal(type, 'CREDIT_CARD_CHARGE')) return 'Credit Card Charge'
  if (equal(type, 'AP_INVOICE')) return 'AP Invoice'
  return 'All Submissions'
}

export const csvHeader = (data) => {
  const list = []
  data.forEach((item) => {
    if (!equal(item, 'id')) {
      list.push({ label: firstCapAndSplit(item), key: item })
    }
  })
  return list
}

export const currentDateToMMDDYYYY = () => {
  const date = new Date()
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export const abc = (date) => {
  const value = date.split('-')
  return `${value[1]}-${value[2]}-${value[0]}`
}

export const changeName = (name) => {
  if (equal(name, 'PROJECT_ADMIN')) return 'Project Admin'
  if (equal(name, 'COMPANY_ADMIN')) return 'Company Admin'
  if (equal(name, 'REVIEWER')) return 'Reviewer'
  if (equal(name, 'ASSISTANT')) return 'Assistant'
  if (equal(name, 'MANAGER')) return 'Manager'
  if (equal(name, 'BASIC')) return 'Basic'

  return name
}

export const convertMDY = (date) => {
  let d = new Date(date).getDate()
  let m = new Date(date).getMonth() + 1
  const y = new Date(date).getFullYear()
  if (d < 10) d = `0${d}`
  if (m < 10) m = `0${m}`
  return `${m}/${d}/${y}`
}

export const handleReviewerStatus = (data, userId) => {
  let reviewerStatus = false
  let paid = ''
  let paidDate = ''
  if (data.length) {
    data.forEach((item) => {
      if (item?.reviewerUserId?.userId?.id === userId && item?.inputFinance) {
        reviewerStatus = true
        paid = item?.paid
        paidDate = item?.paidDate
      }
    })
  }
  return { reviewerStatus, paid, paidDate }
}
export const handleReviewerStatus1 = (data, userId) => {
  let reviewerStatus = false
  let paid = ''
  let paidDate = ''
  if (data.length) {
    const approverData = data.find(
      (item) => item?.isFinal && item?.inputFinance,
    )

    if (approverData) {
      reviewerStatus = approverData?.inputFinance
      paid = approverData?.paid
      paidDate = approverData?.paidDate

      return { reviewerStatus, paid, paidDate }
    }
    const ownData = data.find(
      (item) =>
        item?.reviewerUserId?.userId?.id === userId && item?.inputFinance,
    )

    if (ownData) {
      reviewerStatus = ownData?.inputFinance
      paid = ownData?.paid
      paidDate = ownData?.paidDate
    }
  }
  return { reviewerStatus, paid, paidDate }
}

export const shortName = (name) => {
  if (name === 'PURCHASE_ORDER') return 'PO'
  if (name === 'CREDIT_CARD_CHARGE') return 'CRD'
  if (name === 'CHECK_REQUEST' || name === 'WIRE_TRANSFER') return 'AP'
  return name
}

export const checkSearchValue = (searchData) => {
  const data = { ...searchData }

  if (data.formName === 'all') {
    data.formName = ''
  }
  if (data.qualifiedExpense === 'all') {
    data.qualifiedExpense = ''
  }
  if (data.inputFinance === 'all') {
    data.inputFinance = ''
  }

  return data
}

export const reportBuilderTableData = (reportBuilderDataList, fields) => {
  const filterValue = []
  reportBuilderDataList?.data?.forEach((item, index) => {
    fields.forEach((data) => {
      if (filterValue[index]) {
        if (equal(data, 'formName')) {
          filterValue[index][data] = CapitalizeSubmissions(item[data])
        } else if (equal(data, 'createdBy')) {
          filterValue[index][
            data
          ] = `${item[data]?.userId?.firstName} ${item[data]?.userId?.lastName}`
        } else if (equal(data, 'accountSubtotals')) {
          const ab = []
          item[data].forEach((list) => {
            const { __typename, ...accountSub } = list
            ab.push(accountSub)
          })
          filterValue[index][data] = JSON.stringify(ab)
        } else if (
          // equal(data, 'qualifiedExpense') ||
          equal(data, 'inputFinance')
        ) {
          filterValue[index][data] = item[data] ? 'True' : 'False'
        } else if (
          equal(data, 'submissionDate') ||
          equal(data, 'dateRequired') ||
          equal(data, 'createdAt')
        ) {
          filterValue[index][data] = convertUTCMDY(item[data])
        } else if (equal(data, 'bankName')) {
          filterValue[index][data] = item?.bankInfo?.bankName
        } else if (equal(data, 'bankAccountNumber')) {
          filterValue[index][data] = item?.bankInfo?.bankAccountNumber
        } else if (equal(data, 'bankAddress')) {
          filterValue[index][data] = item?.bankInfo?.bankAddress
        } else if (equal(data, 'routingNumber')) {
          filterValue[index][data] = item?.bankInfo?.routingNumber
        } else if (equal(data, 'currency')) {
          filterValue[index][data] = item?.bankInfo?.currency
        } else if (equal(data, 'swiftCode')) {
          filterValue[index][data] = item?.bankInfo?.swiftCode
        } else if (equal(data, 'additionalBankNotes')) {
          filterValue[index][data] = item?.bankInfo?.additionalBankNotes
        } else if (equal(data, 'bankInfo')) {
          filterValue[index][data] = item?.bankInfo?.bankInfo
        } else if (equal(data, 'v1TransactionNumber')) {
          filterValue[index][data] = item?.v1TransactionNumber
            ? item?.v1TransactionNumber
            : ''
        } else if (equal(data, 'audit')) {
          filterValue[index][data] = item?.audit ? 'True' : 'False'
        } else if (equal(data, 'covidCost')) {
          filterValue[index][data] = item?.covidCost
            ? 'True'
            : ['PURCHASE_ORDER', 'CHECK_REQUEST', 'WIRE_TRANSFER'].includes(
                item?.formName,
              )
            ? ''
            : 'False'
        } else if (equal(data, 'episode')) {
          filterValue[index][data] = item?.episode
        } else if (equal(data, 'paidDate')) {
          filterValue[index][data] = item?.paidDate
        } else if (equal(data, 'paid')) {
          filterValue[index][data] = item?.paid
        } else if (equal(data, 'additionalNotes')) {
          filterValue[index][data] = item?.additionalNotes
        } else {
          filterValue[index][data] = item[data]
        }
      } else {
        filterValue[index] = {}
        if (equal(data, 'formName')) {
          filterValue[index][data] = CapitalizeSubmissions(item[data])
        } else if (equal(data, 'createdBy')) {
          filterValue[index][
            data
          ] = `${item[data]?.userId?.firstName} ${item[data]?.userId?.lastName}`
        } else if (equal(data, 'accountSubtotals')) {
          const ab = []
          item[data].forEach((list) => {
            const { __typename, ...accountSub } = list
            ab.push(accountSub)
          })
          filterValue[index][data] = JSON.stringify(ab)
        } else if (
          // equal(data, 'qualifiedExpense') ||
          equal(data, 'inputFinance')
        ) {
          filterValue[index][data] = item[data] ? 'True' : 'False'
        } else if (
          equal(data, 'submissionDate') ||
          equal(data, 'dateRequired') ||
          equal(data, 'createdAt')
        ) {
          filterValue[index][data] = convertUTCMDY(item[data])
        } else if (equal(data, 'bankName')) {
          filterValue[index][data] = item?.bankInfo?.bankName
        } else if (equal(data, 'bankAccountNumber')) {
          filterValue[index][data] = item?.bankInfo?.bankAccountNumber
        } else if (equal(data, 'bankAddress')) {
          filterValue[index][data] = item?.bankInfo?.bankAddress
        } else if (equal(data, 'routingNumber')) {
          filterValue[index][data] = item?.bankInfo?.routingNumber
        } else if (equal(data, 'currency')) {
          filterValue[index][data] = item?.bankInfo?.currency
        } else if (equal(data, 'swiftCode')) {
          filterValue[index][data] = item?.bankInfo?.swiftCode
        } else if (equal(data, 'additionalBankNotes')) {
          filterValue[index][data] = item?.bankInfo?.additionalBankNotes
        } else if (equal(data, 'bankInfo')) {
          filterValue[index][data] = item?.bankInfo?.bankInfo
        } else {
          filterValue[index][data] = item[data]
        }
      }
    })
  })

  return filterValue
}

export const setFileList = (response, type = false) => {
  const file = {
    name: 'Proof Of Payment',
    file: response?.proofOfPayment || null,
  }
  const w9Upload = { name: 'W9 Upload', file: response?.w9Upload || null }
  const contract = {
    name: 'Contract Upload',
    file: response?.contractUpload || null,
  }
  const invoiceUpload = {
    name: 'Invoice Upload',
    file: response?.invoiceUpload || null,
  }
  if (!type) {
    return [file, w9Upload, invoiceUpload, contract]
  }
  return [
    file,
    w9Upload,
    contract,
    {
      name: 'Invoice',
      file: response?.invoice,
    },
  ]
}

export const checkName = (fname, lname) => {
  if (!fname && !lname) {
    return 'N/A'
  }
  if (fname && !lname) {
    return `${fname}`
  }
  if (!fname && lname) {
    return `${lname}`
  }
  return `${fname} ${lname}`
}

export const setApprovalStatus = (approvalItem, userId) => {
  const response = {
    status: '',
    id: '',
    isFinalStatus: '',
  }
  if (length(approvalItem)) {
    approvalItem.forEach((item) => {
      const id = item?.approvalUserId?.userId?.id
      if (equal(id, userId)) {
        response.status = item?.status
        response.id = item?.id
      }
      if (equal(item?.isFinal, true)) {
        response.isFinalStatus = item?.status
      }
    })
  }
  return response
}
export const getApprovalNames = (approvalItem, approvalMatrixData) => {
  const approvalNames = []
  if (length(approvalItem)) {
    approvalItem.forEach((item) => {
      if (equal(item?.status, 'APPROVED')) {
        const { approvalUserId } = item
        if (
          (equal(approvalUserId?.role, companyProjectRole) &&
            equal(
              approvalUserId?.id,
              approvalMatrixData?.approverProjectAdmin?.id,
            )) ||
          (equal(approvalUserId?.role, companyManagerRole) &&
            equal(approvalUserId?.id, approvalMatrixData?.mainApprover?.id))
        ) {
          const firstName = item?.approvalUserId?.name?.firstName
          const lastName = item?.approvalUserId?.name?.lastName
          const fullName =
            firstName && lastName
              ? `${firstName} ${lastName}`.trim()
              : 'Unknown Approver'

          approvalNames.push(fullName)
        }
      }
    })
  }
  return ternary(length(approvalNames), approvalNames.join(','), '')
}

export const setTabType = (index) => {
  if (equal(index, 0)) return 'PURCHASE_ORDER'
  if (equal(index, 1)) return 'WIRE_TRANSFER'
  if (equal(index, 2)) return 'CREDIT_CARD_CHARGE'
  if (equal(index, 3)) return 'CHECK_REQUEST'
  return 'AP_INVOICE'
}

export const setTabTypeNew = (index) => {
  if (equal(index, 0)) return 'Configuration'
  if (equal(index, 1)) return 'PURCHASE_ORDER'
  if (equal(index, 2)) return 'WIRE_TRANSFER'
  if (equal(index, 3)) return 'CHECK_REQUEST'
  return 'AP_INVOICE'
}

export const getCreatedUser = (item) => {
  const firstName = item?.createdBy?.userId?.firstName
  const lastName = item?.createdBy?.userId?.lastName
  return `${firstName || ''} ${lastName || ''}`
}

export const setTabIndex = (type) => {
  if (equal(type, 'PURCHASE_ORDER')) return 0
  if (equal(type, 'WIRE_TRANSFER')) return 1
  if (equal(type, 'CREDIT_CARD_CHARGE')) return 2
  if (equal(type, 'CHECK_REQUEST')) return 3
  return 4
}

export const handleNumAfterDecimal = (number) => {
  if (number && typeof number === 'number') {
    // return number.toFixed(2)
    return Math.round(number * 100) / 100
  }
  return number
}

export const getPickUpAndReturnDate = (data) => {
  let newData
  if (data.length) {
    data.forEach((item) => {
      if (item?.type === 'EQUIPMENT_RENTAL') {
        newData = {
          pickUpDate: convertUTCMDY(item?.pickUpDate),
          returnDate: convertUTCMDY(item?.returnDate),
        }
      }
    })
  }
  return newData
}

export const setAuditTabType = (index) => {
  if (equal(index, 0)) return 'W-9s'
  if (equal(index, 1)) return 'Invoices'
  return 'Proof of Payment'
}

export const setAuditTabIndex = (type) => {
  if (equal(type, 'Invoices')) return 1
  if (equal(type, 'Proof of Payment')) return 2
  return 0
}

export const changeDateFormat = (date) => {
  const splitDate = date.split('-')
  return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
}

export const popConvert = (name) => {
  if (equal(name, 'proofOfPayment')) {
    return 'POP'
  }
  if (equal(name, 'w9')) {
    return 'W9'
  }
  if (equal(name, 'invoice')) {
    return 'Invoice'
  }
  return name
}

const stringifyAccountIds = (arr) =>
  arr?.map((obj) => ({
    ...obj,
    accountCodeWithName: `${obj?.accountId || ''} ${obj?.accountName || ''}`,
  }))

export const findIndexByAccountCodeWithName = (arr, code) =>
  stringifyAccountIds(arr)?.findIndex((obj) =>
    equal(obj?.accountCodeWithName, code),
  )

export const getAccountCodeIndex = (inputValue, charOfAccountList, status) => {
  let index = ''
  if (inputValue) {
    const value = inputValue.match(/[a-z]+|[^a-z]+/gi)[0]
    if (status) {
      index = charOfAccountList.findIndex((x) => equal(x.accountId, value))
    } else {
      index = charOfAccountList?.findIndex((x) =>
        equal(x.accountId, Number(value)),
      )
    }

    return index
  }

  return inputValue
}

export const getAccountCodeValue = (value, charOfAccountList, status) => {
  if (value) {
    return getAccountCodeIndex(value, charOfAccountList, status)
  }
  return {}
}

export const handlePOName = (name) => {
  if (equal(name, 'MatchesPO')) return 'Matches PO'
  if (equal(name, 'OverPO')) return 'Over PO'
  if (equal(name, 'UnderPO')) return 'Under PO'
  return ''
}

export const checkTourSkip = () => {
  const isSkip = loadStateFn('skip')
  if (isSkip && equal(isSkip, 'true')) {
    return true
  }
  return false
}
export const dashboardTourSkip = () => {
  const isDashboard = loadStateFn('dashboardTour')
  if (isDashboard && equal(isDashboard, 'true')) {
    return true
  }
  return false
}

let temp = []
let obj = {}
let find = []
export const sideBarVisit = (value) => {
  const projectNames = loadStateFn('projectName')
  if (projectNames) {
    sideBarValues?.push(projectNames)
  }
  sideBarValues.forEach((item) => {
    if (equal(item, value)) {
      const findIndex = sideBarValues?.findIndex((data) => equal(data, value))
      obj = { sideBar: value }
      if (notEqual(findIndex, -1)) {
        temp = obj
      }
    }
  })
  const oldValue = JSON.parse(loadStateFn('sideBarValues'))
    ? JSON.parse(loadStateFn('sideBarValues'))
    : []

  if (length(oldValue)) {
    find =
      length(temp) &&
      temp?.map((item) => {
        const findValues = oldValue.find((i) =>
          equal(i?.sideBar, item?.sideBar),
        )
        return findValues
      })
  }

  const clone = length(find) ? [...find, obj] : [...oldValue, obj]
  if (length(clone)) {
    saveStateFn('sideBarValues', JSON.stringify(clone))
  } else {
    saveStateFn('sideBarValues', JSON.stringify([]))
  }
}

export const viewSubmissionStage = (submissionStage) => {
  switch (submissionStage) {
    case 'SUBMITTED':
      return 'Ready to Review'

    case 'REVIEWED':
      return 'Ready to Input'

    case 'CREATED':
      return 'Ready to Approve'

    case 'EDITED':
      return 'EDITED'

    case 'PAID':
      return 'PAID'

    default: {
      return ''
    }
  }
}

// export const convertNumber = (value) => {
//   const arr = []
//   const convertData = value.split('-')
//   convertData.forEach((item) => {
//     const d = Number(item)
//     arr.push(d)
//   })
//   const joinValue = arr.join('-')

// }

export const checkApproverExist = (id, listOfManagers) => {
  if (id) {
    const findValue = listOfManagers?.find((item) => notEqual(item?.id, id))
    if (findValue) {
      return findValue?.id
    }
    return ''
  }
  return ''
}

export const formatCurrency = (number) =>
  number?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })

export const convertToCamelCase = (str) =>
  str?.toLowerCase()?.replace(/ (\w)/g, (_, c) => c.toUpperCase())
